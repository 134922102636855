import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import API from "../apienv";
import { useLocation } from "react-router-dom";
import download from "downloadjs";
import { AuthContext } from "../auth.jsx";

const ExportButton = () => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);

  const handleExport = async () => {
    const query = new URLSearchParams(location.search);
    const token = await currentUser.getIdToken();

    const response = await fetch(`${API}/api/export?${query.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const status = query.get("status") || "all";
      const from = query.get("from") || "start";
      const to = query.get("to") || "end";
      const competitionId = query.get("competitionId") || "all";
      const filename = `nominees_${status}_${from}_to_${to}_${competitionId}.xlsx`;
      download(
        blob,
        filename,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } else {
      console.error("Failed to export nominees");
    }
  };

  return (
    <Button variant="dark" onClick={handleExport}>
      Export Nominees
    </Button>
  );
};

export default ExportButton;
